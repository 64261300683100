.imageDiv {
  display: inline-block;
}

.itemImage {
  width: 100%;
  border-radius: 5%;
}

.descriptionDiv {
  width: 55%;
  float: right;
}

.descriptionTitle {
  font-size: 3rem;
  margin-bottom: 0;
}

.descriptionPrice {
  font-size: 3rem;
  margin: 1% auto 5% auto;
}

.description {
  width: 80%;
}

.buttonDiv {
  margin: 10% auto;
}

@media screen and (max-width: 2853px) {
  .descriptionDiv {
    float: none;
    width: 100%;
  }

  .descriptionPrice {
    font-size: 3rem;
    margin: 1% auto 1% auto;
  }

  .description {
    width: 40%;
    margin: 0 auto;
  }

  .buttonDiv {
    margin: 2% auto;
  }
}

@media screen and (max-width: 950px) {
  .descriptionTitle {
    font-size: 2.5rem;
  }

  .description {
    width: 80%;
  }

  .buttonDiv {
    margin: 4% auto;
  }
}
