table {
  width: 100%;
  padding: 0 6% 6% 6%;
}

th {
  text-align: left;
  padding-bottom: 1%;
  font-size: 1.1rem;
}

td {
  font-weight: bold;
}
