.button {
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 1%;
}

.button:hover {
  background-color: #23c483;
  color: #fff;
  transform: translateY(-7px);
}

.button:active {
  transform: translateY(-1px);
}

.favoriteButton {
  padding: 0;
  font-size: 32px;
  transform: translateY(5px);
}

.favoriteButton:hover {
  color: red;
  background-color: white;
}
