html {
  background-image: url("../assets/landing_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.nav {
  text-align: center;
  background-color: #d82137;
  height: 6rem;
  font-family: "Montserrat";
}

.navList {
  list-style-type: none;
  margin-top: 1%;
  display: inline-block;
}
.navItem {
  display: inline;
  margin-right: 2rem;
}

.link {
  color: ghostwhite;
  text-decoration: none;
}

.logo {
  position: absolute;
  z-index: 1;
  left: 5%;
  height: 200px;
  width: 200px;
}

.contact {
  position: absolute;
  right: 5%;
}

.relative {
  position: relative;
}

.link:hover {
  color: black;
}

@media only screen and (max-width: 620px) {
  .nav {
    height: 12.5rem;
  }

  .contact {
    position: static;
  }

  .navList {
    margin: 0;
    transform: translateY(-15px);
  }

  .navItem {
    display: block;
    margin: 0;
  }

  .logo {
    position: static;
    height: 100px;
    width: 110px;
  }
}
