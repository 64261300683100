.leaflet-container {
  width: 98%;
  height: 400px;
  margin-top: 1%;
  margin-bottom: 3%;
}

span {
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .leaflet-container {
    margin-top: 6%;
  }
}
