html {
  all: unset;
}

.title {
  display: inline-block;
  color: #d82137;
  margin: 0 2% 0 2%;
  font-weight: bolder;
  text-decoration: none;
  font-size: 1.5rem;
  font-family: "Montserrat";
}

.navbar {
  display: inline-block;
  list-style: none;
  width: 80%;
  text-align: center;
  margin-top: 0;
  font-family: "Montserrat";
  margin-bottom: 1%;
}

.navItem {
  display: inline;
  margin-right: 5%;
  font-weight: bolder;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  color: red;
}

.cartButton {
  border: none;
  text-decoration: none;
  background-color: transparent;
}
.cartIcon {
  transform: translateY(10px);
}

.noEntry {
  text-decoration: line-through;
}

@media screen and (max-width: 804px) {
  .cartButton {
    display: block;
    margin: 0 auto;
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 630px) {
  .title {
    display: block;
    text-align: center;
    margin: 0;
    width: 95%;
  }

  .navbar {
    width: 100%;
    margin: 0 auto;
  }

  .navItem {
    display: block;
  }
}
