/*.dishes {
  text-align: center;
  padding-left: 1%;
}
*/

.layout {
  width: 80%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  gap: 16px;
  padding: 0 2%;
}

@media screen and (max-width: 1935px) {
  .layout {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1455px) {
  .layout {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 975px) {
  .layout {
    grid-template-columns: repeat(1, 1fr);
  }
}
