.aboutContainer {
  margin-bottom: 8%;
}

.aboutDescription {
  padding: 0 5%;
}

.aboutTitle {
  margin-top: 0;
  font-size: 5rem;
}

.aboutImage {
  float: left;
  margin: 0 2% 0 2%;
  width: 350px;
}

.aboutLogo {
  margin-top: 13%;
}

.aboutText {
  margin: 0;
  font-size: 2rem;
}

.contactTitle {
  margin-top: 0;
  font-size: 5rem;
}

.contactContainer {
  margin: 1% 0 2% 4%;
  height: 10%;
}

.contactInfo {
  text-align: center;
  width: 100%;
}

.title {
  display: inline-block;
  color: #d82137;
  margin-right: 4%;
  font-weight: bolder;
}

.contactText {
  display: inline-block;
  margin-right: 5%;
}

.contactNumber {
  font-size: 1.2rem;
  font-weight: 500;
  display: inline;
}

.contactAddress {
  font-size: 1rem;
  display: inline;
}

.contactTime {
  font-size: 1rem;
  display: inline;
}

.leaflet-container {
  margin: 2% auto;
}

@media screen and (max-width: 900px) {
  .contactTitle {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .aboutTitle {
    font-size: 3.5rem;
  }
  .contactNumber {
    font-size: 1rem;
  }

  .title {
    margin: 0;
    display: block;
    text-align: center;
  }

  .contactText {
    display: block;
    margin-right: 1%;
    text-align: center;
  }

  .aboutImage {
    width: 300px;
  }
}

@media screen and (max-width: 805px) {
  .contactText {
    display: block;
    margin-bottom: 1%;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .aboutImage {
    float: none;
    margin: 0 auto 6% auto;
    display: block;
  }

  .aboutImage {
    width: 200px;
  }
}
