.menuNav {
  display: inline-block;
  width: 100%;
  margin-left: 2%;
  padding: 0;
  font-family: "Montserrat";
  text-align: center;
}

.menuItem {
  display: inline-block;
  margin-right: 1%;
  background-color: red;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.menuLink {
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease 0s;
}

.menuLink:hover {
  color: black;
}

.menuActive {
  color: black;
}

@media screen and (max-width: 1052px) {
  .menuItem {
    margin-bottom: 1%;
  }
}

@media screen and (max-width: 630px) {
  .menuItem {
    display: block;
    margin-bottom: 4%;
  }
}

@media screen and (max-width: 600px) {
  .menuNav {
    padding: 0 20%;
  }
}
