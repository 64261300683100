.layout {
  width: 100%;

  display: grid;
  grid:
    "header" auto
    "main" 1fr
    "footer" auto
    / 1fr;
}

.header {
  grid-area: header;
  margin: 2rem 0;
}
.main {
  grid-area: main;
  padding: 1.5%;
  width: 50%;
  margin: 0 auto;
}
.footer {
  grid-area: footer;
}

.title {
  margin-left: 2%;
}

@media screen and (max-width: 2853px) {
  .main {
    text-align: center;
  }
}
