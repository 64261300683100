.background {
  background-image: url("../assets/landing_background.png");
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.slogan {
  text-align: center;
  text-shadow: 2px 2px 8px #000000;
  margin: 5% 0;
  color: white;
  font-size: 10rem;
  font-family: "Open Sans";
}

@media screen and (max-width: 620px) {
  .slogan {
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .slogan {
    font-size: 6rem;
  }
}

@media screen and (max-width: 290px) {
  .slogan {
    font-size: 5rem;
  }
}
