/* Original
.card {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-bottom: 60px;
}
.card > div {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.5);
}
.cardImage {
  width: 400px;
  height: 250px;
}
.cardImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.cardText {
  margin: -30px auto;
  margin-bottom: -50px;
  height: 300px;
  width: 300px;
  background-color: #1d1c20;
  color: #fff;
  padding: 20px;
}
.cardMealType {
  font-style: italic;
}
.cardTitle {
  font-size: 2.2rem;
  margin-bottom: 20px;
  margin-top: 5px;
}
.cardBody {
  font-size: 1.25rem;
}
.cardPrice {
  width: 100px;
  height: 100px;
  background-color: #970c0a;
  color: #fff;
  margin-left: auto;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
*/

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}
.productCard {
  width: 380px;
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
  margin: 50px auto;
  background: #fafafa;
}

.badge {
  position: absolute;
  left: 0;
  top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: red;
  color: #fff;
  padding: 3px 10px;
}

.productTumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  padding: 50px;
}

.productTumb img {
  max-width: 143%;
  max-height: 143%;
  cursor: pointer;
}

.productDetails {
  padding: 30px;
}

.productCatagory {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 18px;
}

.productDetails h4 p {
  font-weight: 500;
  display: block;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #363636;
  text-decoration: none;
  transition: 0.3s;
  width: 57%;
}

.productDetails h4 p:hover {
  cursor: pointer;
  color: red;
}

.productDetails p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 18px;
  color: #999;
}

.productBottomDetails {
  overflow: hidden;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.productBottomDetails div {
  float: left;
  width: 50%;
}

.productPrice {
  font-size: 18px;
  color: red;
  font-weight: 600;
}

.productPrice small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
}

.productLinks {
  text-align: right;
}

.productLinks svg {
  display: inline-block;
  margin-left: 5px;
  transition: 0.3s;
  width: 20px;
  height: 20px;
}

.productLinks svg:hover {
  fill: gold;
  cursor: pointer;
}
.cartButton {
  border: none;
  margin-right: 5%;
  background-color: transparent;
  transform: translateY(1px);
}

.favoriteButton {
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 430px) {
  .productCard {
    width: 90%;
  }
}
